import React from "react"

const Illustration = ({ src, className }) => (
  <div
    className={`illustration ${className}`}
    style={{
      backgroundImage: `url(${src})`,
    }}
  />
)

export default Illustration
