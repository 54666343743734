import React from "react"

import Illustration from "../components/Illustration"
import SEO from "../components/SEO"
import Navbar from "../components/Navbar"

import useTranslation from "../translations/translate"

import NoData from "../components/assets/media/undraw_no_data.svg"

const NotFound = ({ pageContext: { pageName } }) => {
  const { t } = useTranslation()

  return (
    <>
      <Navbar />
      <SEO
        title={t("page.404.title")}
        description={t("page.404.description")}
        pageName={pageName}
        noIndex
      />
      <section className="container content d-flex">
        <div className="row w-100 my-auto flex-column-reverse flex-md-row">
          <div className="col-12 text-center">
            <Illustration src={NoData} className="mb-10" />

            <h1 className="mb-5">{t("page.404.title")}</h1>
            <p className="lead">{t("page.404.description")}</p>
          </div>
        </div>
      </section>
    </>
  )
}

export default NotFound
